.header {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.2rem;
  border-bottom: 1px solid var(--lightGray2);
  background-color: var(--white);
  min-height: 59px;
}

.headerLogo {
  width: 64px;
  margin-right: 16px;
}

.headerTitle {
  font-size: calc(100% + 0.1vw);
  font-weight: 600;
  color: var(--darkGray);
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
@import './variables.scss';
@import './normalize.scss';

.leftSide {
  display: flex;
  flex-direction: column;
  width: 35%;
  padding: 5rem;
  background-color: var(--white);
}

.rightSide {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  background-color: var(--lightGray2);
  position: relative;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .leftSide {
    padding: 3rem;
  }
}

@media (max-width: 550px) {
  .leftSide {
    width: 100%;
    height: auto;
    padding: 2rem;
    overflow-y: auto;
  }
}

.adImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.rotateImage {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;

  img {
    width: 32px;
  }
}

.content {
}

.section {
  position: relative;
  flex: 1;
  display: flex;
}

.leftSide {
  display: flex;
  flex-direction: column;
  width: 35%;
  padding: 5rem;
  background-color: var(--white);
}

.rightSide {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 65%;
  background-color: var(--lightGray2);
  position: relative;
  overflow: hidden;
}

.videoNotFound {
  text-align: center;
  color: var(--darkGray);
  font-size: 2rem;
  font-weight: bold;
}

#video-container {
  width: 100%;
}

@media (max-width: 800px) {
  .section {
    flex-direction: column;
    align-items: center;
  }

  .leftSide {
    width: 100%;
  }

  .rightSide {
    width: 100%;
  }
}

.title {
  font-size: calc(100% + 2vw);
  line-height: 110%;
  margin: 0;
  margin-bottom: 2rem;
  margin-top: 3rem;
  color: var(--darkGray);
}

.subtitle {
  font-size: calc(100% + 0.1vw);
  color: var(--darkGray);
  margin-bottom: 6rem;
}

.item {
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 8rem;
  }

  .itemContent {
    display: flex;
    flex-direction: column;
    flex: 1;

    h4 {
      color: var(--darkGray);
      font-size: 1.8rem;
    }

    p {
      font-size: 1.5rem;
      color: var(--darkGray);
    }
  }

  .icon {
    border-radius: 50%;
    margin-right: 1rem;
  }
}

@media (max-width: 1024px) {
  .title {
    font-size: calc(90% + 2vw);
  }

  .subtitle {
    font-size: calc(90% + 0.1vw);
    margin-bottom: 5rem;
  }

  .item {
    &:not(:last-child) {
      margin-bottom: 5rem;
    }

    .itemContent {
      h4 {
        font-size: 1.6rem;
      }

      p {
        font-size: 1.3rem;
      }
    }
  }
}

@media (max-width: 550px) {
  .title {
    font-size: calc(90% + 2vw);
    margin-bottom: 1rem;
    margin-top: 0rem;
  }

  .subtitle {
    font-size: calc(90% + 0.1vw);
    margin-bottom: 3rem;
  }

  .item {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    .itemContent {
      h4 {
        font-size: 1.6rem;
      }

      p {
        font-size: 1.3rem;
      }
    }

    .icon {
      border-radius: 50%;
      margin-right: 1rem;
    }
  }
}

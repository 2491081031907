.loginContainer {
  z-index: 11111;
  background-color: var(--white);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100vw;
  min-height: 90vh;
  transition: 0.3s all;
}

.hide {
  height: 0;
  opacity: 0;
  z-index: 0;
}

.content {
  display: flex;
  align-items: flex-start;
  flex: 1;
  flex-direction: row;
  min-height: auto;
}

.leftSide {
  display: flex;
  flex-direction: column;
  width: 35%;
  height: 100%;
  padding: 5rem;
}

.leftSide__title {
  font-size: calc(100% + 2vw);
  line-height: 110%;
  margin: 0;
  margin-bottom: 20px;
  color: var(--darkGray);
}

.leftSide__subtitle {
  font-size: calc(100% + 0.1vw);
  color: var(--darkGray);
}

.leftSide__form {
  margin-top: 4vh;

  button {
    margin-bottom: 1rem;

    &:disabled {
      background-color: var(--lightGray1) !important;
      color: var(--darkGray) !important;
    }
  }
}

.leftSide__author {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  width: 70%;

  label {
    font-weight: 700;
    font-size: calc(100% + 0.1vw);
    color: var(--darkGray);
    margin-bottom: 0.6rem;
  }
  input {
    font-weight: 400;
    border-radius: 3px;
    font-size: calc(90% + 0.1vw);
    color: var(--darkGray);
    padding: 1.2rem;
    outline: none;
    border: 1px solid var(--lightPrimary);
    margin-bottom: 1.6rem;
  }
}

.inputContainer__button {
  margin-left: auto;
  border-radius: 3px;
  outline: none;
  background-color: var(--primary);
  border: none;
  cursor: pointer;
  color: var(--white);
  padding: 1.4rem 1.2rem;
  font-size: calc(100% + 0.05vw);
  font-weight: 600;
  min-width: 19rem;
  margin-top: 1.4rem;
}

.leftSide__contact {
  border: 1px solid var(--lightGray2);
  padding: calc(3%) calc(5%);
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.contactTitle {
  font-size: calc(100% + 0.2vw);
  color: var(--darkGray);
  margin: 0;
  margin-bottom: 10px;
}

.contactCard {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.contactImg {
  width: calc(12% + 0.2vw);
  margin-right: 1.2rem;
  object-fit: contain;
}

.contactInfo {
  display: flex;
  flex-direction: column;

  p {
    font-size: calc(75% + 0.1vw);
    color: var(--darkGray);
    margin: 0;
  }

  strong {
    font-size: calc(100% + 0.1vw);
    color: var(--darkGray);
  }
}

.contactButton {
  margin-left: auto;
  outline: none;
  background-color: var(--lightGray1);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  color: var(--darkGray);
  padding: 10px;
  font-size: calc(90% + 0.01vw);
  font-weight: 600;
}

.rightSide {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 65%;
  height: 100%;
  background-color: var(--lightGray2);
  padding: 50px 50px 60px 50px;
}

.rightSide__img {
  width: 80%;
}

.rightSide__author {
  width: 80%;
  margin-top: 50px;
  margin-left: 20px;
  display: flex;
  align-items: flex-start;
}

.rightSide__partners {
  width: 80%;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: auto;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .imgContainer {
    flex-basis: 33.33%;
    text-align: center;
    margin-top: 2rem;
    padding: 0 1rem;

    @media (max-width: 1024px) {
      flex-basis: 50%;
    }

    @media (max-width: 629px) {
      flex-basis: 100%;
      margin-top: 1.2rem;
    }
  }
}

.authorImg {
  width: 60px;
  margin-right: 20px;
}

.authorContent {
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    margin-top: 7px;
    margin-bottom: 5px;
    font-size: calc(100% + 0.3vw);
    color: var(--darkGray);
  }

  strong {
    color: var(--darkGray);
  }

  &:nth-child(1) {
    font-weight: 700;
  }
}

@media (max-width: 1024px) {
  .leftSide {
    padding: 3rem;
  }

  .leftSide__title {
    font-size: calc(80% + 2vw);
  }

  .leftSide__subtitle {
    font-size: calc(80% + 0.1vw);
  }

  .contactTitle {
    font-size: calc(70% + 0.2vw);
  }

  .contactCard {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .contactImg {
    width: calc(12% + 0.2vw);
    margin-right: 1.2rem;
    object-fit: contain;
  }

  .contactInfo {
    p {
      font-size: calc(65% + 0.1vw);
    }

    strong {
      font-size: calc(70% + 0.1vw);
    }
  }

  .contactButton {
    padding: 5px 7px;
    font-size: calc(55% + 0.01vw);
  }

  .inputContainer {
    width: 90%;
  }
}

@media (max-width: 735px) {
  .rightSide {
    padding: 50px 16px;
  }
}

@media (max-width: 550px) {
  .rightSide {
    display: none;
  }

  .leftSide {
    width: 100%;
    height: auto;
    padding: 2rem;
    overflow-y: auto;
  }

  .rightSide__author {
    width: 100%;
    margin-top: 50px;
    display: flex;
    align-items: flex-start;
    margin-left: 0;
  }

  .authorContent {
    p {
      font-size: calc(90% + 0.3vw);
      margin-bottom: 1rem;
    }
  }

  .inputContainer {
    label {
      font-size: calc(90% + 0.1vw);
      margin-bottom: 0.5rem;
    }
    input {
      font-size: calc(80% + 0.1vw);
      padding: 1.2rem;
      margin-bottom: 1rem;
    }
  }

  .inputContainer__button {
    padding: 1.2rem 1.2rem;
    font-size: calc(80% + 0.05vw);
    min-width: 19rem;
    margin: 1.4rem 0;
  }
}

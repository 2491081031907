.content {
  position: relative;
  flex: 1;
  display: flex;
}

.videoNotFound {
  text-align: center;
  color: var(--darkGray);
  font-size: 2rem;
  font-weight: bold;
}

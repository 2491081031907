:root {
  --base-font-size: 62.5%;
  --font: 'Open Sans', sans-serif;
  --white: #ffffff;
  --black: #000000;
  --darkGray: #343434;
  --lightGray1: #ecedef;
  --lightGray2: #f1f4f8;
  --primary: #08274f;
  --lightPrimary: #d8e1ec;
}

.videoContainer {
  display: flex;
  width: 100%;
}

video::-webkit-media-controls-fullscreen-button,
video::-webkit-media-controls-play-button,
video::-webkit-media-controls-pausebutton {
  display: none;
}

.mute {
  position: absolute;
  width: 2rem;
  height: 2rem;
  bottom: 2rem;
  left: 2rem;
  z-index: 10000;
  filter: invert(1);
  outline: none;
}
